
/*@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=arial:wght@400;500;700&display=swap");

:root {
    /*--font-base: "DM Sans", sans-serif;*/
    --font-base: "Arial", sans-serif;

    --primary-color: #edf2f8;
    --secondary-color: #313bac;
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;


    /* DARK THEME */
    --dp00: #121212;
    --dp01: #1E1E1E;
    --dp02: #222222;
    --dp03: #242424;
    --dp04: #272727;
    --dp06: #2C2C2C;
    --dp08: #2D2D2D;
    --dp12: #333333;
    --dp16: #353535;
    --dp24: #515151;
    --dark-hover:#808080FF;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    line-height: 1.5rem;
}
