.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: .5rem 2rem;
  //background: rgba(255, 255, 255, 0.25);
  background: none;
  //backdrop-filter: blur(4px);
  //backdrop-filter: blur(10px);
  //-webkit-backdrop-filter: blur(4px);
  //border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 3001;

  @media(max-width: 767px) {
    padding: .5rem 2rem .5rem .5rem;
  }
}

.app__navbar-scroll {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem;
  background: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  //border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 3001;

  @media(max-width: 767px) {
    padding: .5rem 2rem .5rem .5rem;
  }
}


#light .app__navbar-scroll {
  background: rgba(255, 255, 255, .8);
}


#dark .app__navbar-scroll {
  background: rgba(18, 18, 18, .85);
}


.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

    img {
      //width: 90px;
      //height: 20px ;
      //width: 120px;
      width: 180px;
      //width: 176px;

      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
      }

      @media(max-width: 450px) {
        width: 150px;
      }
    }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-right: 45px;
  //margin-right: 160px;

  li {
    margin: 0 1rem;
    cursor: pointer;
    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      //color: var(--gray-color);
      color:  var(--secondary-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 800;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--gray-color)
      }
    }

    &:hover {
      div {
        background-color: var(--secondary-color)
      }
    }
  }



  @media screen and (max-width: 900px) {
    display: none;
  }
}


#light  .app__navbar-links {
  li {
    a {
      color:  var(--secondary-color);

      &:hover {
        color: var(--gray-color)
      }
    }
    &:hover {
      div {
        background-color: var(--secondary-color)
      }
    }
  }
}


#dark  .app__navbar-links {
  li {
    a {
      color:  var(--primary-color) !important;

      &:hover {
        color: var(--gray-color)
      }
    }
    &:hover {
      div {
        background-color: var(--primary-color)
      }
    }
  }
}

.app__navbar-switch {
  //flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
  //color:  var(--secondary-color);
  font-weight: 800;

  p {
      color:  var(--secondary-color) !important;

      &:hover {
        color: var(--gray-color)
      }

    &:hover {
      div {
        background-color: var(--secondary-color)
      }
    }
  }


  @media(max-width: 899px) {
    flex: 1 1;
    margin-right: 120px;
    justify-content: center;
  }

  @media(max-width: 599px) {
    margin-right: 0;
  }
}

#light .app__navbar-switch {
  p {
    color:  var(--secondary-color) !important;
    &:hover {
      color: var(--gray-color)
    }
    &:hover {
      div {
        background-color: var(--secondary-color)
      }
    }
  }
}

#dark .app__navbar-switch {
  p {
    color:  var(--primary-color) !important;
    &:hover {
      color: var(--gray-color)
    }
    &:hover {
      div {
        background-color: var(--primary-color)
      }
    }
  }
}









.app__navbar-links-scroll {
  a {
    //color: var(--gray-color);
    color:var(--secondary-color);
    //color:  #fff;
    text-decoration: none;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 500;

    transition: all 0.3s ease-in-out;

    &:hover {
      //color:var(--secondary-color)
      color: var(--gray-color);
    }
  }
}


.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    //background: url('../../assets/bgWhite.png');
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}


#light .app__navbar-menu {
  background-color: var(--secondary-color);
  svg {
    color: var(--white-color);
  }
  div {
    background-color: var(--white-color);
    svg {
      color: var(--secondary-color);
    }
    ul {
      li {
        a {
          color: var(--gray-color);
          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }
  }
}

#dark .app__navbar-menu {
  background-color: var(--dark-hover);
  //border: .5px solid #fff;
  svg {
    color: var(--white-color);
  }
  div {
    background-color: var(--dp00);
    svg {
      color: var(--primary-color);
    }
    ul {
      li {
        a {
          color: var(--primary-color);
          &:hover {
            color: var(--gray-color);
          }
        }
      }
    }
  }
}
