//::-webkit-scrollbar {
//  display: none;
//}



/* ================================================
|   |   |   |   Preloader
================================================ */
::-webkit-scrollbar {
  display: none;
}

.stars__canvas-styles {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #000000;
}

.earth__canvas-styles {

  width: 600px;
  height: 600px;

  @media screen and (max-width: 767px) {
      height: 350px;
      width: 350px;
  }




  //@media (min-width: 1280px) {
  //    height: auto;
  //}
  //
  //@media (min-width: 1280px) {
  //    flex: 1 1 0%;
  //}
}




.preloader {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.status {
  background-image: url("./assets/wine-preloader-2.gif");
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -35px;
  margin-left: -35px;
}



/* ==========================
 | | | | VideoWrap CSS
=============================
*/


.video__container {
  width: 100%;
  //min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.video__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  //padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}


.video__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  z-index: 1001;
  position: absolute;
  right: 0;
  top: 50%;

  .video__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;
    border: 1px solid #000;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

.video__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  z-index: 1001;
  position: absolute;
  top: 81%;

  @media screen and (max-width: 767px) {
    top: 78%;
  }

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}



#light .video__social {
  a {
    background-color: var(--white-color);
    border: 1px solid var(--lightGray-color);
    svg {
      color: var(--gray-color);
    }
    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }
  }
}

#dark .video__social {
  a {
    background-color: var(--dp01);
    border: 1px solid var(--white-color);
    svg {
      color: var(--white-color);
    }
    &:hover {
      background-color: var(--dp24);
      border-color: var(--dp24);

      svg {
        color: var(--white-color);
      }
    }
  }
}



.video-copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1001;
  position: absolute;
  top: 84%;
  right: 6%;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}




.video-head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;
  position: absolute;
  top: 18%;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}



/* =============================
|  |  | End of Video Wrap CSS
================================ */





.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

#light .app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

#dark .app {
  background-color: var(--dp00);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

#light .app__whitebg {
  background-color: var(--white-color);
}

#dark .app__whitebg {
  background-color: var(--dp01);
}



.app__primarybg {
  background-color: var(--primary-color);
}

#light .app__primarybg {
  background-color: var(--primary-color);
}

#dark .app__primarybg {
  background-color: var(--dp00);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

#light .head-text {
  color: var(--black-color);
}

#dark .head-text {
  color: var(--white-color);
}

#light .head-text span {
  color: var(--secondary-color);
}

#dark .head-text span {
  color: var(--primary-color);
}



.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}


#light .p-text {
  color: var(--gray-color);

}

#dark .p-text {
  color: #e2e8f0;;

}


.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

#light .bold-text {
  color: var(--black-color);

}

#dark .bold-text {
  color: var(--white-color);
}

.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }

  }

  @media(max-width: 976px) {
    display: none;
  }
}



#light .app__social {
  a {
    background-color: var(--white-color);
    border: 1px solid var(--lightGray-color);
    svg {
      color: var(--gray-color);
    }
    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }
  }
}

#dark .app__social {
  a {
    background-color: var(--dp01);
    border: 1px solid var(--white-color);
    svg {
      color: var(--white-color);
    }
    &:hover {
      background-color: var(--dp24);
      border-color: var(--dp24);

      svg {
        color: var(--white-color);
      }
    }
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }

  @media(max-width: 976px) {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}



.single-product__markdown {
  //margin-top: 150px;
  background: #fff;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 767px) {
    margin-bottom: 1rem;
  }
}

#light .single-product__markdown {
  //margin-top: 150px;
  background: #fff;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 767px) {
    margin-bottom: 1rem;
  }
}

#dark .single-product__markdown {
  //margin-top: 150px;
  background: var(--dp06);
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  p {
    color: #e2e8f0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #e2e8f0;
  }
  @media(max-width: 767px) {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
  }
}

.single-product__markdown2 {
  //margin-top: 150px;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
}

#light .single-product__markdown2 {
  //margin-top: 150px;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
}

#dark .single-product__markdown2 {
  //margin-top: 150px;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;

  p {
    color: #fff;
  }

  h2 {
    color: #e2e8f0;
  }

  h4 {
    color: #e2e8f0;
  }
}

.single-skill__markdown {
  background: #fff;
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;

  p {
    //max-width: 650px;
    text-align: left;
    margin: 0 auto;
  }

  ul {
    text-align: left;
  }


  @media(max-width: 767px) {
    margin-bottom: 1rem;
    padding: 1.5rem;
    width: 675px;


  }

  @media(max-width: 700px) {
    margin-bottom: 1rem;
    padding: 1.3rem;
    width: 575px;
    width: 575px;
  }

  @media(max-width: 600px) {
    margin-bottom: 1rem;
    padding: 1.3rem;
    width: 575px;
    width: 475px;
  }

  @media(max-width: 500px) {
    margin-bottom: 1rem;
    padding: 1.3rem;
    width: 475px;
    width: 400px;
  }

  @media(max-width: 425px) {
    margin-bottom: 1rem;
    padding: 1.3rem;
    width: 400px;
    width: 370px;
  }


  @media(max-width: 375px) {
    margin-bottom: 1rem;
    padding: 1.1rem;
    width: 370px;
  }

}

#light .single-skill__markdown {
  background: #fff;
}

#dark .single-skill__markdown {
  background: var(--dp06);

  p {
    color: #e2e8f0;
  }

  h2 {
    color: #fff;
  }

  h3 {
    color: #fff;
  }
}


.single-skill__canvas {
  background-color: #fff;
}

#light .single-skill__canvas {
  background-color: #fff;
}

#dark .single-skill__canvas {
  background-color: var(--dp06);
}


.main-skills__items {
  //margin-top: 150px;
  background: #fff;
  padding: 2rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;

  @media(max-width: 767px) {
    margin-bottom: 1rem;
  }
}

#light .main-skills__items {
  background: #fff;
}

#dark .main-skills__items {
  background-color: var(--dp02);
}


.portfolio-page__bg {
  background-color: var(--primary-color);
  padding-top: 40px;

  @media(max-width: 599px) {
    padding-top: 0;
  }
}

#light .portfolio-page__bg {
  background-color: var(--primary-color);
}

#dark .portfolio-page__bg {
  background-color: var(--dp00);
}

.skill-page__bg {
  background-color: var(--white-color);
  padding-top: 40px;


}

#light .skill-page__bg {
  background-color: var(--white-color);
}

#dark .skill-page__bg {
  background-color: var(--dp01);
}


code {
  transform: translateZ(0);
  min-width: 100%;
  float: left;
  & > span {
    display: block;
  }


  ::selection {
    background: #8b8b8b;
  }
}


.codeStyle {
  width: 100%;
  margin: 20px auto !important;
  font-size: 16px;
  padding: 1.5rem !important;

  @media(max-width: 976px) {
    font-size: 15px;
  }

  @media(max-width: 767px) {
    font-size: 14px;
  }

  @media(max-width: 600px) {
    font-size: 13px;
  }

  @media(max-width: 500px) {
    font-size: 12px;
  }
}


span.linenumber { display: none !important; }

.skill-name_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}




.video__container {
  @media(max-width: 599px) {
    display: none;
  }
}

#work {
  @media(max-width: 599px) {
    padding-top: 40px;
  }
}

.app__work-filter {
  @media(max-width: 599px) {
    display: none !important;
  }
}

.app__work-portfolio {
  @media(max-width: 599px) {
    padding-top: 20px !important;
  }
}
