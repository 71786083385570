.app__video {
  flex: 1 1;
  width: 100%;
  flex-direction: column;
}


.video-bg {
  width: 100%;
  //height: 100vh;
  font-family: Impact, Haettenschweiler,'Arial Narrow Bold', sans-serif;


  video {
    width: 100%;
    height: 100%;
    //height: 100vh;
    object-fit: cover;
    z-index: 1;
  }
}

.video-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  z-index: 500;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffffcc;
  //background-color: #edf2f8cc;
  //background-color: rgba(0,0,0,.8);
}

#light .video-overlay {
  background-color: #ffffffcc;
}

#dark .video-overlay {
  //background-color: #121212cc;
  background-color: rgba(18,18,18,.85);
}

.video__about-item {
  position: relative;
  width: 210px;
  flex-direction: column;
  /* margin: 2rem; */
  padding: 0.6rem;
  border-radius: 15px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 25px rgba(0,0,0,0.2);
  }
}

#light .video__about-item {
  background-color: #fff;
}

#dark .video__about-item {
  background-color: var(--dp12);
}



//.video__about-item:hover {
//  box-shadow: 0 0 25px rgb(0 0 0 / 20%);
//}




.video__card-text {
  font-family: var(--font-base);
  position: absolute;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
  top: 155px;
  //top: 55%;
  //margin-left: 50px;
}

#light .video__card-text {
  background-color: #fff;
}

#dark .video__card-text {
  background-color: var(--dp12);
}



.video__card-text2 {
  font-family: var(--font-base);
  position: absolute;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
  top: -9px;
  //top: 55%;
  //margin-left: 50px;
}


.video__card-desc {
  font-size: 0.8rem;
  text-align: left;
  //color: var(--gray-color);
  line-height: 1.5;
  font-family: var(--font-base);
  margin-bottom: 20px;
  font-weight: 800;
  color: var(--black-color);
  text-align: center;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}










#home {
  position: relative;
  background: url('../../assets/bgIMG.png');
  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  .video__wrapper {
    padding: 0;

    .video-copyright {
      display: none;
    }
  }
}

.video__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.video__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.video__header-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 100%;

  .badge-cmp, .tag-cmp {
    padding:1rem 2rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.video__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;
  position: absolute;
  right: 20%;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }
  div:nth-child(2) {
    margin: 1.75rem;
    height: 150px;
    width: 150px;
  }
  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 400px;
      height: 400px;
    }
    div:nth-child(2) {
      height: 170px;
      width: 170px;
    }
    div:nth-child(3) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.video__header-img {
  flex: 1;
  height: 100%;
  right: 44%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    //bottom: 0;
    bottom: -130px;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1200px) {
    //margin: 2rem 0;
    right: 39.5%;
  }

  @media screen and (max-width: 935px) {
    right: 36.5%;
  }

  @media screen and (max-width: 820px) {
    right: 35%;
  }

  @media screen and (max-width: 414px) {
    right: 19%;
  }



  @media screen and (max-width: 767px) {
    display: none;
  }
}



.video__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.video__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  position: absolute;
  //top: 52%;

  @media screen and (max-width: 1350px) {
    max-width: 800px;
  }


}

.video__profile-item {
  //width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.video__work-tag {
  position: absolute;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
  //top: -25px;
  top: 180px;
  font-family: var(--font-base);
  right: 34px;
}


.video__work-img {
  width: 100%;
  //height: 230px;
  height: 180px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}



.video__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}
