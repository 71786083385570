.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}



#light .app__work-filter {
  .app__work-filter-item {
    background-color: #fff;
    color: #000;
    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }



  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

#dark .app__work-filter {
  .app__work-filter-item {
    background-color: var(--dp04);
    //color: #000;
    &:hover {
      background-color: var(--dark-hover);
      color: #fff;
    }
  }



  .item-active {
      background-color: var(--dark-hover);
      color: #fff;
  }
}



.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__skill-item2 {
    width: 350px;
    flex-direction: column;
    margin: 2rem 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0,0,0,0.2);
    }

    @media(max-width: 767px) {
      width: 100%;
      margin: 0 0 1rem 0;
    }


    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-item-img {
  display: flex;
  max-width: 1200px;

  @media(max-width: 767px) {
    display: block;
  }
}

.app__work-img2 {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
  }

  .app__work-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;
  }

  .app__work-tag2 {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -242px;
  }
}




button {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: #edf2f8;

  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #a1a0a1;
    color: #fff;
  }
}

ul li::marker {
  color: #fff;
}

.work__all-projects {
  //margin-bottom: 20px;
  text-decoration: none;
  color: var(--secondary-color);
  font-weight: 600;
}
